import { axiosInstance } from "../../axios";

const POSTRequestReceived = "/api/ReportRequestReceived/RequestReceived";
const POSTExportToExcelRequestReceived = "/api/ReportRequestReceived/ExportToExcel";
const POSTExportToPDFRequestReceived = "/api/ReportRequestReceived/ExportToPDF";

const POSTCompletedJobs = "/api/ReportCompletedJobs/CompletedJobs";
const POSTExportToExcelCompletedJobs = "/api/ReportCompletedJobs/ExportToExcel";
const POSTExportToPDFCompletedJobs = "/api/ReportRequestReceived/ExportToPDF";

const POSTNonCompletedJobs = "/api/ReportNonCompletedJobs/NonCompletedJobs";

const RequestReceived = async function (data) {
  try {
    const response = await axiosInstance.bhAPIInstance.post(POSTRequestReceived, data);
    return parseList(response);
  } catch (error) {
    return null;
  }
};


const ExportToExcelRequestReceived = async function (data) {
  try {
    const response = await axiosInstance.bhAPIInstance.post(POSTExportToExcelRequestReceived, data);
    return parseList(response);
  } catch (error) {
    return null;
  }
};

const ExportToPDFRequestReceived = async function (data) {
  try {
    const response = await axiosInstance.bhAPIInstance.post(POSTExportToPDFRequestReceived, data);
    return parseList(response);
  } catch (error) {
    return null;
  }
};



const CompletedJobs = async function (data) {
  try {
    const response = await axiosInstance.bhAPIInstance.post(POSTCompletedJobs, data);
    return parseList(response);
  } catch (error) {
    return null;
  }
};

const ExportToExcelCompletedJobs = async function (data) {
  try {
    const response = await axiosInstance.bhAPIInstance.post(POSTExportToExcelCompletedJobs, data);
    return parseList(response);
  } catch (error) {
    return null;
  }
};


const ExportToPDFCompletedJobs = async function (data) {
  try {
    const response = await axiosInstance.bhAPIInstance.post(POSTExportToPDFCompletedJobs, data);
    return parseList(response);
  } catch (error) {
    return null;
  }
};

const NonCompletedJobs = async function (data) {
  try {
    const response = await axiosInstance.bhAPIInstance.post(POSTNonCompletedJobs, data);
    return parseList(response);
  } catch (error) {
    return null;
  }
};


const parseList = response => {
  if (response.status !== 200) throw Error(response.message);
  if (!response.data) return [];
  let list = response.data;
  if (typeof list !== "object") {
    list = [];
  }
  return list;
};


export const reportsData = {
  RequestReceived,
  ExportToExcelRequestReceived,
  ExportToPDFRequestReceived,
  CompletedJobs,
  ExportToExcelCompletedJobs,
  ExportToPDFCompletedJobs,
  NonCompletedJobs
};
